import { __decorate, __extends } from "tslib";
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { createUser } from '../../api/users';
import { Button } from '../../components/Button';
import { Modal } from '../../components/Modal';
import { isValidEmailFormat } from '../../utils/utils';
import S from './admins.styl';
var AddAdminModal = /** @class */ (function (_super) {
    __extends(AddAdminModal, _super);
    function AddAdminModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            loading: false,
            emailInputError: false,
            passwordInputError: false,
            firstNameInputError: false,
            lastNameInputError: false,
        };
        _this.onChangeAction = function (e) {
            var name = e.currentTarget.name;
            if (name === 'email') {
                _this.setState({ emailInputError: false });
            }
            if (name === 'password') {
                _this.setState({ passwordInputError: false });
            }
        };
        _this.isUpdated = function () {
            if (_this.props.isUpdated) {
                _this.props.isUpdated();
            }
        };
        _this.onClose = function () {
            if (_this.props.onClose) {
                _this.props.onClose();
            }
        };
        return _this;
    }
    AddAdminModal.prototype.onSubmit = function (event) {
        var _this = this;
        event.preventDefault();
        var elements = event.currentTarget.elements;
        var valid = true;
        var email = elements.email.value;
        var password = elements.password.value;
        var firstName = elements.firstName.value;
        var lastName = elements.firstName.value;
        if (!isValidEmailFormat(email)) {
            this.setState({ emailInputError: true });
            valid = false;
        }
        if (password.length === 0) {
            this.setState({ passwordInputError: true });
            valid = false;
        }
        if (!valid) {
            return;
        }
        var request = {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
            device: 'WEB',
        };
        this.setState({ loading: true });
        createUser(request).then(function () {
            _this.setState({ loading: false });
            _this.isUpdated();
        }).catch(function (error) {
            _this.setState({ loading: false });
            alert(error);
        });
    };
    AddAdminModal.prototype.render = function () {
        var _a = this, props = _a.props, state = _a.state;
        var i18n = props.store.i18n;
        var loading = state.loading;
        return (React.createElement(Modal, { className: props.className, onRequestClose: props.onClose },
            React.createElement("div", { className: S.adminsAddView },
                React.createElement("p", { className: S.adminsHeaderText }, i18n.adminsCreateTitle),
                React.createElement("form", { className: "" + S.addDialogContent, method: "get", action: "/", onSubmit: this.onSubmit, noValidate: true },
                    React.createElement("div", null,
                        React.createElement("div", { className: S.addInputWrapper },
                            React.createElement("label", { className: S.addInputTitle }, i18n.loginViewEmailPlaceholder),
                            React.createElement("input", { className: S.addInput + " textInput", name: "email", type: "email", autoComplete: "username", onInput: this.onChangeAction, placeholder: i18n.loginViewEmailPlaceholder })),
                        React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.emailInputError ? 'inputErrorActive' : '') }, i18n.validationCreateEmailError),
                        React.createElement("div", { className: S.addInputWrapper },
                            React.createElement("label", { className: S.addInputTitle }, i18n.loginViewPasswordPlaceholder),
                            React.createElement("input", { className: S.addInput + " textInput", name: "password", type: "password", autoComplete: "current-password", onInput: this.onChangeAction, placeholder: i18n.loginViewPasswordPlaceholder })),
                        React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.passwordInputError ? 'inputErrorActive' : '') }, i18n.validationCreatePasswordError),
                        React.createElement("div", { className: S.addInputWrapper },
                            React.createElement("label", { className: S.addInputTitle }, i18n.adminsFirstName),
                            React.createElement("input", { className: S.addInput + " textInput", name: "firstName", autoComplete: "current-password", onInput: this.onChangeAction, placeholder: i18n.adminsFirstNamePlaceholder })),
                        React.createElement("div", { className: S.addInputWrapper },
                            React.createElement("label", { className: S.addInputTitle }, i18n.adminsLastName),
                            React.createElement("input", { className: S.addInput + " textInput", name: "lastName", type: "password", autoComplete: "current-password", onInput: this.onChangeAction, placeholder: i18n.adminsLastNamePlaceholder }))),
                    React.createElement(Button, { className: "primaryButton fullwidthButton " + S.adminsModalButton, type: "submit", spinner: loading }, i18n.actionCreate),
                    React.createElement(Button, { className: "primaryButton fullwidthButton " + S.adminsModalButton + " " + S.adminsModalCancel, type: "button", spinner: loading, onClick: this.onClose }, i18n.alertModalCancel)))));
    };
    __decorate([
        action.bound
    ], AddAdminModal.prototype, "onSubmit", null);
    AddAdminModal = __decorate([
        inject('store'),
        observer
    ], AddAdminModal);
    return AddAdminModal;
}(React.Component));
export { AddAdminModal };
