import { __decorate, __extends } from "tslib";
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { checkIfLogged } from '../api';
import { LoginResponse } from '../auth/types';
import { Alert, AlertCloseType } from '../components/Alert';
import { SpinnerOverlay } from '../components/Spinner';
import { Router } from '../router/Router';
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            alertMessage: undefined,
            alertTitle: undefined,
            alertNeedsConfirm: undefined,
        };
        _this.onAlertClose = function (closeType) {
            _this.setState({
                alertMessage: undefined,
                alertNeedsConfirm: undefined,
            });
            if (_this.props.store.customConfirmCallback) {
                runInAction(function () {
                    _this.props.store.customConfirmCallback(closeType === AlertCloseType.Confirm);
                    _this.props.store.customConfirmCallback = undefined;
                });
            }
        };
        return _this;
    }
    App.prototype.componentDidMount = function () {
        var _this = this;
        window.alert = function (alertMessage, alertTitle) {
            console.error(alertTitle);
            _this.setState({
                alertMessage: alertMessage,
                alertTitle: alertTitle,
            });
        };
        window.confirm = function (alertMessage) {
            var alertTitle = _this.props.store.customConfirmTitle;
            _this.setState({
                alertMessage: alertMessage,
                alertTitle: alertTitle,
                alertNeedsConfirm: true,
            });
            return false;
        };
        runInAction(function () {
            var store = _this.props.store;
            if (checkIfLogged()) {
                store.loginStatus = LoginResponse.Success;
            }
            else {
                store.loginStatus = LoginResponse.Unauthenticated;
            }
        });
    };
    App.prototype.render = function () {
        var state = this.state;
        var store = this.props.store;
        var _a = store, activeScreen = _a.activeScreen, loginStatus = _a.loginStatus;
        return ((loginStatus === LoginResponse.Reconnect) ?
            React.createElement(SpinnerOverlay, null)
            :
                React.createElement(Router, null,
                    activeScreen,
                    state.alertMessage &&
                        React.createElement(Alert, { alertMessage: state.alertMessage, alertTitle: state.alertTitle, alertNeedsConfirm: state.alertNeedsConfirm, onClose: this.onAlertClose })));
    };
    App = __decorate([
        inject('store'),
        observer
    ], App);
    return App;
}(React.Component));
export { App };
