export var CustomerStatus;
(function (CustomerStatus) {
    CustomerStatus["approved"] = "approved";
    CustomerStatus["rejected"] = "rejected";
    CustomerStatus["unverified"] = "unverified";
    CustomerStatus["pending"] = "pending";
    CustomerStatus["waiting"] = "waiting";
})(CustomerStatus || (CustomerStatus = {}));
export var CustomerType;
(function (CustomerType) {
    CustomerType["referrer"] = "referrer";
    CustomerType["customer"] = "customer";
})(CustomerType || (CustomerType = {}));
