import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Route } from '../router/routes';
import { logOut } from '../auth/actions';
import { setLocale } from '../i18n/actions';
import { Link } from './Link';
import S from './SideBar.styl';
export var SideBarItem;
(function (SideBarItem) {
    SideBarItem[SideBarItem["Profile"] = 1] = "Profile";
    SideBarItem[SideBarItem["Dashboard"] = 2] = "Dashboard";
    SideBarItem[SideBarItem["Admins"] = 3] = "Admins";
    SideBarItem[SideBarItem["Settings"] = 4] = "Settings";
    SideBarItem[SideBarItem["Claims"] = 5] = "Claims";
    SideBarItem[SideBarItem["Customers"] = 6] = "Customers";
    SideBarItem[SideBarItem["PushNotifications"] = 7] = "PushNotifications";
    SideBarItem[SideBarItem["Faq"] = 8] = "Faq";
    SideBarItem[SideBarItem["Feed"] = 9] = "Feed";
})(SideBarItem || (SideBarItem = {}));
var SideBar = /** @class */ (function (_super) {
    __extends(SideBar, _super);
    function SideBar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            companyMenuOpened: false,
        };
        _this.ref = null;
        _this.ref2 = null;
        _this.onLogOutClick = function () {
            logOut(_this.props.store);
        };
        _this.switchLocale = function () {
            var store = _this.props.store;
            setLocale(store, store.i18n.otherLocale);
        };
        _this.onWindowClick = function (event) {
            var _a = _this, ref = _a.ref, ref2 = _a.ref2;
            var el = event.target;
            while (el) {
                if (el === ref) {
                    // the click was inside the dropdown but not on an autoClose item
                    return;
                }
                if (el === ref2) {
                    return;
                }
                if (el instanceof HTMLElement && el.classList.contains('sideBarCompanyMenu')) {
                    break;
                }
                el = el.parentNode;
            }
        };
        return _this;
    }
    SideBar.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    SideBar.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (this.state.companyMenuOpened !== prevState.companyMenuOpened) {
            if (this.state.companyMenuOpened) {
                window.addEventListener('click', this.onWindowClick, true);
            }
            else {
                window.removeEventListener('click', this.onWindowClick, true);
            }
        }
    };
    SideBar.prototype.render = function () {
        var props = this.props;
        var _a = props.store, i18n = _a.i18n, sideBarCollapsed = _a.sideBarCollapsed;
        var activeItem = props.activeItem;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: S.sideBarWrapper },
                React.createElement("div", { className: S.sideBar + " " + (sideBarCollapsed ? S.sideBarCollapsed : '') + " " + (props.className || '') },
                    React.createElement("nav", { className: S.sideBarScroll },
                        React.createElement("div", { className: S.sideBarTop }, i18n.sideBarTitle),
                        React.createElement("ul", { className: S.sideBarMain },
                            React.createElement("li", { className: S.sideBarItem + " " + ((activeItem === SideBarItem.Dashboard) ? S.sideBarItemActive : '') },
                                React.createElement(Link, { className: S.sideBarItemLink, to: Route.Dashboard },
                                    React.createElement("span", { className: S.sideBarItemIcon },
                                        React.createElement("svg", { width: "25", height: "22", viewBox: "0 -4 25 22" },
                                            React.createElement("path", { d: "M23.725,4.765c0.705,0 1.275,0.481 1.275,1.076l0,11.083c0,0.594 -0.57,1.076 -1.275,1.076l-22.449,0c-0.704,0 -1.276,-0.482 -1.276,-1.076l0,-11.083c0,-0.595 0.572,-1.076 1.276,-1.076l22.449,0Zm-20.845,-1.342c-0.382,0 -0.691,-0.261 -0.691,-0.583c0,-0.321 0.309,-0.583 0.691,-0.583l19.296,0c0.382,0 0.691,0.262 0.691,0.583c0,0.322 -0.309,0.583 -0.691,0.583l-19.296,0Zm1.67,-2.257c-0.345,0 -0.626,-0.236 -0.626,-0.527l0,-0.111c0,-0.292 0.281,-0.528 0.626,-0.528l15.662,0c0.345,0 0.626,0.236 0.626,0.528l0,0.111c0,0.291 -0.281,0.527 -0.626,0.527l-15.662,0Zm3.142,10.733l2.2,1.874l1.122,0.954l6.662,-5.591l-1.122,-0.954l-5.531,4.642l-2.209,-1.88l-1.122,0.955Z", fill: "#999" }))),
                                    React.createElement("span", { className: S.sideBarItemText }, i18n.sideBarDashboard)),
                                React.createElement("div", { className: S.sideBarToolTip },
                                    React.createElement("span", { className: S.sideBarToolTipArrow }),
                                    React.createElement("span", { className: S.sideBarToolTipText }, i18n.sideBarDashboard))),
                            React.createElement("li", { className: S.sideBarItem + " " + ((activeItem === SideBarItem.Admins) ? S.sideBarItemActive : '') },
                                React.createElement(Link, { className: S.sideBarItemLink, to: Route.Admins },
                                    React.createElement("span", { className: S.sideBarItemIcon },
                                        React.createElement("svg", { width: "25", height: "22", viewBox: "0 0 24 24" },
                                            React.createElement("path", { fill: "#999", d: "M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M15.6,8.34C16.67,8.34 17.53,9.2 17.53,10.27C17.53,11.34 16.67,12.2 15.6,12.2A1.93,1.93 0 0,1 13.67,10.27C13.66,9.2 14.53,8.34 15.6,8.34M9.6,6.76C10.9,6.76 11.96,7.82 11.96,9.12C11.96,10.42 10.9,11.5 9.6,11.5C8.3,11.5 7.24,10.42 7.24,9.12C7.24,7.81 8.29,6.76 9.6,6.76M9.6,15.89V19.64C7.2,18.89 5.3,17.04 4.46,14.68C5.5,13.56 8.13,13 9.6,13C10.13,13 10.8,13.07 11.5,13.21C9.86,14.08 9.6,15.23 9.6,15.89M12,20C11.72,20 11.46,20 11.2,19.96V15.89C11.2,14.47 14.14,13.76 15.6,13.76C16.67,13.76 18.5,14.15 19.44,14.91C18.27,17.88 15.38,20 12,20Z" }))),
                                    React.createElement("span", { className: S.sideBarItemText }, i18n.sideBarAdmins)),
                                React.createElement("div", { className: S.sideBarToolTip },
                                    React.createElement("span", { className: S.sideBarToolTipArrow }),
                                    React.createElement("span", { className: S.sideBarToolTipText }, i18n.sideBarAdmins))),
                            React.createElement("li", { className: S.sideBarItem + " " + ((activeItem === SideBarItem.Customers) ? S.sideBarItemActive : '') },
                                React.createElement(Link, { className: S.sideBarItemLink, to: Route.Customers },
                                    React.createElement("span", { className: S.sideBarItemIcon },
                                        React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24" },
                                            React.createElement("path", { fill: "#999", d: "M18,19H6V17.6C6,15.6 10,14.5 12,14.5C14,14.5 18,15.6 18,17.6M12,7A3,3 0 0,1 15,10A3,3 0 0,1 12,13A3,3 0 0,1 9,10A3,3 0 0,1 12,7M12,3A1,1 0 0,1 13,4A1,1 0 0,1 12,5A1,1 0 0,1 11,4A1,1 0 0,1 12,3M19,3H14.82C14.4,1.84 13.3,1 12,1C10.7,1 9.6,1.84 9.18,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3Z" }))),
                                    React.createElement("span", { className: S.sideBarItemText }, i18n.sideBarCustomers)),
                                React.createElement("div", { className: S.sideBarToolTip },
                                    React.createElement("span", { className: S.sideBarToolTipArrow }),
                                    React.createElement("span", { className: S.sideBarToolTipText }, i18n.sideBarCustomers))),
                            React.createElement("li", { className: S.sideBarItem + " " + ((activeItem === SideBarItem.PushNotifications) ? S.sideBarItemActive : '') },
                                React.createElement(Link, { className: S.sideBarItemLink, to: Route.PushNotifications },
                                    React.createElement("span", { className: S.sideBarItemIcon },
                                        React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24" },
                                            React.createElement("path", { fill: "#999", d: "M21,19V20H3V19L5,17V11C5,7.9 7.03,5.17 10,4.29C10,4.19 10,4.1 10,4A2,2 0 0,1 12,2A2,2 0 0,1 14,4C14,4.1 14,4.19 14,4.29C16.97,5.17 19,7.9 19,11V17L21,19M14,21A2,2 0 0,1 12,23A2,2 0 0,1 10,21M19.75,3.19L18.33,4.61C20.04,6.3 21,8.6 21,11H23C23,8.07 21.84,5.25 19.75,3.19M1,11H3C3,8.6 3.96,6.3 5.67,4.61L4.25,3.19C2.16,5.25 1,8.07 1,11Z" }))),
                                    React.createElement("span", { className: S.sideBarItemText }, i18n.sideBarPushNotifications)),
                                React.createElement("div", { className: S.sideBarToolTip },
                                    React.createElement("span", { className: S.sideBarToolTipArrow }),
                                    React.createElement("span", { className: S.sideBarToolTipText }, i18n.sideBarPushNotifications))),
                            React.createElement("li", { className: S.sideBarItem + " " + ((activeItem === SideBarItem.Faq) ? S.sideBarItemActive : '') },
                                React.createElement(Link, { className: S.sideBarItemLink, to: Route.Faq },
                                    React.createElement("span", { className: S.sideBarItemIcon },
                                        React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24" },
                                            React.createElement("path", { fill: "#999", d: "M18,15H6L2,19V3A1,1 0 0,1 3,2H18A1,1 0 0,1 19,3V14A1,1 0 0,1 18,15M23,9V23L19,19H8A1,1 0 0,1 7,18V17H21V8H22A1,1 0 0,1 23,9M8.19,4C7.32,4 6.62,4.2 6.08,4.59C5.56,5 5.3,5.57 5.31,6.36L5.32,6.39H7.25C7.26,6.09 7.35,5.86 7.53,5.7C7.71,5.55 7.93,5.47 8.19,5.47C8.5,5.47 8.76,5.57 8.94,5.75C9.12,5.94 9.2,6.2 9.2,6.5C9.2,6.82 9.13,7.09 8.97,7.32C8.83,7.55 8.62,7.75 8.36,7.91C7.85,8.25 7.5,8.55 7.31,8.82C7.11,9.08 7,9.5 7,10H9C9,9.69 9.04,9.44 9.13,9.26C9.22,9.08 9.39,8.9 9.64,8.74C10.09,8.5 10.46,8.21 10.75,7.81C11.04,7.41 11.19,7 11.19,6.5C11.19,5.74 10.92,5.13 10.38,4.68C9.85,4.23 9.12,4 8.19,4M7,11V13H9V11H7M13,13H15V11H13V13M13,4V10H15V4H13Z" }))),
                                    React.createElement("span", { className: S.sideBarItemText }, i18n.sideBarFaq)),
                                React.createElement("div", { className: S.sideBarToolTip },
                                    React.createElement("span", { className: S.sideBarToolTipArrow }),
                                    React.createElement("span", { className: S.sideBarToolTipText }, i18n.sideBarFaq))),
                            React.createElement("li", { className: S.sideBarItem + " " + ((activeItem === SideBarItem.Feed) ? S.sideBarItemActive : '') },
                                React.createElement(Link, { className: S.sideBarItemLink, to: Route.Feed },
                                    React.createElement("span", { className: S.sideBarItemIcon },
                                        React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24" },
                                            React.createElement("path", { fill: "#999", d: "M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M7.5,15A1.5,1.5 0 0,0 6,16.5A1.5,1.5 0 0,0 7.5,18A1.5,1.5 0 0,0 9,16.5A1.5,1.5 0 0,0 7.5,15M6,10V12A6,6 0 0,1 12,18H14A8,8 0 0,0 6,10M6,6V8A10,10 0 0,1 16,18H18A12,12 0 0,0 6,6Z" }))),
                                    React.createElement("span", { className: S.sideBarItemText }, i18n.sideBarFeed)),
                                React.createElement("div", { className: S.sideBarToolTip },
                                    React.createElement("span", { className: S.sideBarToolTipArrow }),
                                    React.createElement("span", { className: S.sideBarToolTipText }, i18n.sideBarFeed)))),
                        React.createElement("div", { className: S.sideBarBottomList },
                            React.createElement("div", { className: S.sideBarItem, onClick: this.onLogOutClick },
                                React.createElement("span", { className: S.sideBarItemLink + " " + S.cursorPointer },
                                    React.createElement("svg", { className: S.sideBarItemIcon, width: "24", height: "24", viewBox: "0 0 24 24" },
                                        React.createElement("path", { fill: "#999", d: "M14.08,15.59L16.67,13H7V11H16.67L14.08,8.41L15.5,7L20.5,12L15.5,17L14.08,15.59M19,3A2,2 0 0,1 21,5V9.67L19,7.67V5H5V19H19V16.33L21,14.33V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19Z" })),
                                    React.createElement("div", { className: S.sideBarItemText }, i18n.headerBarLogOut)),
                                React.createElement("div", { className: S.sideBarToolTip },
                                    React.createElement("span", { className: S.sideBarToolTipArrow }),
                                    React.createElement("span", { className: S.sideBarToolTipText }, i18n.headerBarLogOut))),
                            React.createElement("div", { className: S.sideBarLangageSwitch, onClick: this.switchLocale }, !sideBarCollapsed ?
                                i18n.otherLocaleLong
                                :
                                    i18n.otherLocale)),
                        React.createElement("div", { className: S.sideBarBottom },
                            "v. ",
                            __VERSION__,
                            React.createElement("br", null),
                            i18n.sideBarCopyright))))));
    };
    SideBar = __decorate([
        inject('store'),
        observer
    ], SideBar);
    return SideBar;
}(React.Component));
export { SideBar };
