import { __decorate, __extends } from "tslib";
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Dropzone from 'react-dropzone-uploader';
import { getConfig, update } from '../../api/config';
import { deleteDocument, list } from '../../api/document';
import { Button } from '../../components/Button';
import { DateSquare } from '../../components/DateSquare';
import { SpinnerBlock } from '../../components/Spinner';
import { BASE_URL, EndPoints, getAuthHeader, UploadType } from '../../api';
import { PDFIconColored } from '../../icons/PDFIconColored';
import { customConfirm } from '../../utils/utils';
import S from './dashboard.styl';
var DashboardView = /** @class */ (function (_super) {
    __extends(DashboardView, _super);
    function DashboardView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            loading: false,
            loadingDocuments: false,
            phoneInputError: false,
            aboutInputError: false,
            emailInputError: false,
            addressInputError: false,
            longitudeInputError: false,
            latitudeInputError: false,
            openingsInputError: false,
            documents: [],
        };
        _this.dropZonePdf = null;
        _this.getUploadPdfParams = function (_) {
            return {
                url: BASE_URL + EndPoints.upload + '?type=' + UploadType.pdf,
                headers: getAuthHeader(),
            };
        };
        _this.handlePdfChangeStatus = function (params, status) {
            _this.setState({ loadingDocuments: true });
            if (status === 'done') {
                var response = JSON.parse(params.xhr.response);
                _this.setState({
                    attachedPdf: response.data.fileName,
                });
                _this.dropZonePdf.files = [];
                _this.fetchDocuments();
            }
        };
        _this.onChangeAction = function (e) {
            var name = e.currentTarget.name;
            if (name === 'phone') {
                _this.setState({ phoneInputError: false });
            }
            if (name === 'about') {
                _this.setState({ aboutInputError: false });
            }
            if (name === 'email') {
                _this.setState({ emailInputError: false });
            }
            if (name === 'address') {
                _this.setState({ addressInputError: false });
            }
            if (name === 'longitude') {
                _this.setState({ longitudeInputError: false });
            }
            if (name === 'latitude') {
                _this.setState({ latitudeInputError: false });
            }
            if (name === 'openings') {
                _this.setState({ openingsInputError: false });
            }
        };
        _this.deletePDF = function (document) {
            var i18n = _this.props.store.i18n;
            if (document) {
                customConfirm(i18n.alertDeleteDocumentSummary, _this.props.store, function (success) {
                    if (success) {
                        _this.setState({ loadingDocuments: true });
                        var request = {
                            documentId: document.id,
                        };
                        deleteDocument(request).then(function () {
                            _this.setState({ loadingDocuments: false });
                            _this.fetchDocuments();
                        }).catch(function (error) {
                            _this.setState({ loadingDocuments: false });
                            alert(error);
                        });
                    }
                });
            }
        };
        return _this;
    }
    DashboardView.prototype.onSubmit = function (event) {
        var _this = this;
        var i18n = this.props.store.i18n;
        event.preventDefault();
        var elements = event.currentTarget.elements;
        var valid = true;
        var phone = elements.phone.value;
        var about = elements.about.value;
        var email = elements.email.value;
        var address = elements.address.value;
        var longitude = elements.longitude.value;
        var latitude = elements.latitude.value;
        var openings = elements.openings.value;
        if (phone.length === 0) {
            this.setState({ phoneInputError: true });
            valid = false;
        }
        if (about.length === 0) {
            this.setState({ aboutInputError: true });
            valid = false;
        }
        if (email.length === 0) {
            this.setState({ emailInputError: true });
            valid = false;
        }
        if (address.length === 0) {
            this.setState({ addressInputError: true });
            valid = false;
        }
        if (longitude.length === 0) {
            this.setState({ longitudeInputError: true });
            valid = false;
        }
        if (latitude.length === 0) {
            this.setState({ latitudeInputError: true });
            valid = false;
        }
        if (openings.length === 0) {
            this.setState({ openingsInputError: true });
            valid = false;
        }
        if (!valid) {
            return;
        }
        var request = {
            phone: phone,
            email: email,
            about: about,
            address: address,
            longitude: parseFloat(longitude),
            latitude: parseFloat(latitude),
            openings: openings,
        };
        this.setState({ loading: true });
        update(request).then(function (_) {
            _this.fetchData();
        }).catch(function (error) {
            alert(error.toString());
            throw error;
        });
    };
    DashboardView.prototype.componentDidMount = function () {
        var store = this.props.store;
        if (store) {
            this.fetchData();
            this.fetchDocuments();
        }
    };
    DashboardView.prototype.fetchData = function () {
        var _this = this;
        this.setState({ loading: true });
        getConfig().then(function (result) {
            _this.setState({
                loading: false,
                config: result,
            });
        }).catch(function (error) {
            alert(error.toString());
            throw error;
        });
    };
    DashboardView.prototype.fetchDocuments = function () {
        var _this = this;
        list().then(function (result) {
            _this.setState({
                loadingDocuments: false,
                documents: result.items,
            });
        }).catch(function (error) {
            alert(error.toString());
            throw error;
        });
    };
    DashboardView.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _f;
        var _g = this, props = _g.props, state = _g.state;
        var loading = state.loading, loadingDocuments = state.loadingDocuments, config = state.config, documents = state.documents;
        var _h = props.store, i18n = _h.i18n, nbDays = _h.dashboardPeriod;
        var todayDate = new Date();
        return (React.createElement("div", { className: S.dashboardView },
            React.createElement("div", { className: S.dashboardHeaderContainer },
                React.createElement(DateSquare, { className: S.dateSquare, date: todayDate, size: 40 }),
                React.createElement("h1", { className: "moduleTitle" }, i18n.dashboardTitle),
                React.createElement("div", { className: S.dayNotesContainer },
                    React.createElement("span", { className: S.dayNotesDate }, i18n.formatMediumDate(todayDate)))),
            React.createElement("hr", { className: "moduleSeparator" }),
            React.createElement("div", { className: S.dashboardMainBox },
                React.createElement("p", { className: S.dashboardSectionHeader }, i18n.dashboardConfig),
                loading ?
                    React.createElement(SpinnerBlock, null)
                    :
                        React.createElement(React.Fragment, null,
                            React.createElement("form", { className: "" + S.addDialogContent, method: "get", action: "/", onSubmit: this.onSubmit, noValidate: true },
                                React.createElement("div", null,
                                    React.createElement("div", { className: S.addInputWrapper },
                                        React.createElement("label", { className: S.addInputTitle }, i18n.dashboardPhone),
                                        React.createElement("input", { className: S.addInput + " textInput", name: "phone", onInput: this.onChangeAction, placeholder: i18n.dashboardPhone, defaultValue: config === null || config === void 0 ? void 0 : config.phone })),
                                    React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.phoneInputError ? 'inputErrorActive' : '') }, i18n.validationPhoneValidError),
                                    React.createElement("div", { className: S.addInputWrapper },
                                        React.createElement("label", { className: S.addInputTitle }, i18n.loginViewEmailPlaceholder),
                                        React.createElement("input", { className: S.addInput + " textInput", name: "email", onInput: this.onChangeAction, placeholder: i18n.loginViewEmailPlaceholder, defaultValue: config === null || config === void 0 ? void 0 : config.email })),
                                    React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.emailInputError ? 'inputErrorActive' : '') }, i18n.validationEmailValidError),
                                    React.createElement("div", { className: S.addInputWrapper },
                                        React.createElement("label", { className: S.addInputTitle }, i18n.dashboardOpenings),
                                        React.createElement("input", { className: S.addInput + " textInput", name: "openings", onInput: this.onChangeAction, placeholder: i18n.dashboardOpenings, defaultValue: config === null || config === void 0 ? void 0 : config.openings })),
                                    React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.openingsInputError ? 'inputErrorActive' : '') }, i18n.validationOpeningsError),
                                    React.createElement("div", { className: S.addTextareaWrapper },
                                        React.createElement("label", { className: S.addInputTitle }, i18n.dashboardAbout),
                                        React.createElement("textarea", { className: S.addInput + " textInput", name: "about", onInput: this.onChangeAction, placeholder: i18n.dashboardAbout, defaultValue: config === null || config === void 0 ? void 0 : config.about })),
                                    React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.aboutInputError ? 'inputErrorActive' : '') }, i18n.validationAboutError),
                                    React.createElement("div", { className: S.addInputWrapper },
                                        React.createElement("label", { className: S.addInputTitle }, i18n.dashboardAddress),
                                        React.createElement("input", { className: S.addInput + " textInput", name: "address", onInput: this.onChangeAction, placeholder: i18n.dashboardAddress, defaultValue: (_a = config === null || config === void 0 ? void 0 : config.address) === null || _a === void 0 ? void 0 : _a.address })),
                                    React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.addressInputError ? 'inputErrorActive' : '') }, i18n.validationAddressError),
                                    React.createElement("div", { className: S.addInputWrapper },
                                        React.createElement("label", { className: S.addInputTitle }, i18n.dashboardLongitude),
                                        React.createElement("input", { className: S.addInput + " textInput", name: "longitude", onInput: this.onChangeAction, placeholder: i18n.dashboardLongitude, defaultValue: (_c = (_b = config === null || config === void 0 ? void 0 : config.address) === null || _b === void 0 ? void 0 : _b.location) === null || _c === void 0 ? void 0 : _c.lng })),
                                    React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.longitudeInputError ? 'inputErrorActive' : '') }, i18n.validationLongitudeError),
                                    React.createElement("div", { className: S.addInputWrapper },
                                        React.createElement("label", { className: S.addInputTitle }, i18n.dashboardLatitude),
                                        React.createElement("input", { className: S.addInput + " textInput", name: "latitude", onInput: this.onChangeAction, placeholder: i18n.dashboardLatitude, defaultValue: (_f = (_d = config === null || config === void 0 ? void 0 : config.address) === null || _d === void 0 ? void 0 : _d.location) === null || _f === void 0 ? void 0 : _f.lat })),
                                    React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.latitudeInputError ? 'inputErrorActive' : '') }, i18n.validationLatitudeError)),
                                React.createElement(Button, { className: "primaryButton fullwidthButton " + S.dashboardButton, type: "submit", spinner: loading }, i18n.actionUpdate)))),
            React.createElement("div", { className: S.dashboardMainBox },
                React.createElement("p", { className: S.dashboardSectionHeader }, i18n.dashboardPdfList),
                React.createElement("div", { className: "formUploadWrapper" },
                    React.createElement(Dropzone, { ref: function (r) { return _this.dropZonePdf = r; }, getUploadParams: this.getUploadPdfParams, onChangeStatus: this.handlePdfChangeStatus, multiple: false, inputContent: i18n.inputUploaderPlaceholder, canRestart: true, canCancel: false, canRemove: false, accept: "application/pdf" }))),
            React.createElement("div", { className: S.dashboardMainBox }, loadingDocuments ?
                React.createElement(SpinnerBlock, null)
                :
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: S.documentsHeader },
                            React.createElement("div", { className: S.name }, i18n.customersName),
                            React.createElement("div", { className: S.action }, i18n.customersActions)),
                        React.createElement("div", { className: S.documentsList }, documents === null || documents === void 0 ? void 0 : documents.map(function (document, i) { return (React.createElement("div", { className: S.documentItem, key: i },
                            React.createElement("div", { className: S.name },
                                React.createElement(PDFIconColored, null),
                                React.createElement("span", null, document.name || document.fileName)),
                            React.createElement("div", { className: S.action },
                                React.createElement(Button, { className: S.deleteButton, type: "button", onClick: function (_e) { return _this.deletePDF(document); } }, i18n.actionDelete)))); })))),
            React.createElement("br", null),
            React.createElement("br", null)));
    };
    __decorate([
        action.bound
    ], DashboardView.prototype, "onSubmit", null);
    DashboardView = __decorate([
        inject('store'),
        observer
    ], DashboardView);
    return DashboardView;
}(React.Component));
export { DashboardView };
