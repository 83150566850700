import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import { list as customerList } from '../../api/customers';
import { Button } from '../../components/Button';
import { CustomerItem } from '../../components/CustomerItem';
import { SpinnerBlock } from '../../components/Spinner';
import { AddCustomerModal } from './AddCustomerModal';
import S from './customers.styl';
var CustomersView = /** @class */ (function (_super) {
    __extends(CustomersView, _super);
    function CustomersView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            customers: [],
            loading: false,
            showCreateModal: false,
        };
        _this.onUpdate = function () {
            _this.fetchData();
        };
        _this.createAction = function () {
            _this.setState({
                showCreateModal: true,
            });
        };
        _this.onCloseAdd = function () {
            _this.setState({
                showCreateModal: false,
            });
        };
        _this.isUpdatedAdd = function () {
            _this.setState({
                showCreateModal: false,
            });
            _this.fetchData();
        };
        return _this;
    }
    CustomersView.prototype.componentDidMount = function () {
        var store = this.props.store;
        if (store) {
            this.fetchData();
        }
    };
    CustomersView.prototype.fetchData = function () {
        var _this = this;
        this.setState({ loading: true });
        customerList(true).then(function (result) {
            _this.setState({
                loading: false,
                customers: result.items,
            });
        }).catch(function (error) {
            alert(error.toString());
            throw error;
        });
    };
    CustomersView.prototype.render = function () {
        var _this = this;
        var props = this.props;
        var i18n = props.store.i18n;
        var _a = this.state, loading = _a.loading, customers = _a.customers;
        return (React.createElement("div", { className: S.customersView },
            React.createElement("h1", { className: S.customersTitle }, i18n.customersTitle),
            React.createElement("hr", { className: S.customersSeparator }),
            React.createElement("div", { className: S.customersHeaderActions },
                React.createElement(Button, { className: "primaryButton " + S.addButton, type: "button", onClick: this.createAction }, i18n.actionCreate)),
            React.createElement("div", { className: S.customersMainBox }, loading ?
                React.createElement(SpinnerBlock, null)
                :
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: S.customersList }, customers.map(function (customer, i) { return (React.createElement(CustomerItem, { key: i, customer: customer, isUpdated: _this.onUpdate })); })))),
            this.state.showCreateModal &&
                React.createElement(AddCustomerModal, { onClose: this.onCloseAdd, isUpdated: this.isUpdatedAdd })));
    };
    CustomersView = __decorate([
        inject('store'),
        observer
    ], CustomersView);
    return CustomersView;
}(React.Component));
export { CustomersView };
