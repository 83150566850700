import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import { list, positionFaq } from '../../api/faq';
import { Button } from '../../components/Button';
import { FaqItem } from '../../components/FaqItem';
import { SpinnerBlock } from '../../components/Spinner';
import { AddFaqModal } from './AddFaqModal';
import S from './faq.styl';
var FaqView = /** @class */ (function (_super) {
    __extends(FaqView, _super);
    function FaqView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            faqs: [],
            loading: false,
            showCreateModal: false,
        };
        _this.onUpdate = function () {
            _this.fetchData();
        };
        _this.createAction = function () {
            _this.setState({
                showCreateModal: true,
            });
        };
        _this.onCloseAdd = function () {
            _this.setState({
                showCreateModal: false,
                updateItem: undefined,
            });
        };
        _this.isUpdatedAdd = function () {
            _this.onCloseAdd();
            _this.fetchData();
        };
        _this.updateItem = function (item) {
            _this.setState({
                showCreateModal: true,
                updateItem: item,
            });
        };
        _this.changePosition = function (id, position) {
            _this.setState({ loading: true });
            positionFaq(id, position).then(function () {
                _this.fetchData();
            }).catch(function (error) {
                console.error(error);
            });
        };
        return _this;
    }
    FaqView.prototype.componentDidMount = function () {
        var store = this.props.store;
        if (store) {
            this.fetchData();
        }
    };
    FaqView.prototype.fetchData = function () {
        var _this = this;
        this.setState({ loading: true });
        list().then(function (result) {
            _this.setState({
                loading: false,
                faqs: result.items,
            });
        }).catch(function (error) {
            alert(error.toString());
            throw error;
        });
    };
    FaqView.prototype.render = function () {
        var _this = this;
        var props = this.props;
        var i18n = props.store.i18n;
        var _a = this.state, loading = _a.loading, faqs = _a.faqs, updateItem = _a.updateItem;
        return (React.createElement("div", { className: S.faqView },
            React.createElement("h1", { className: S.faqTitle }, i18n.faqTitle),
            React.createElement("hr", { className: S.faqSeparator }),
            React.createElement("div", { className: S.faqHeaderActions },
                React.createElement(Button, { className: "primaryButton " + S.addButton, type: "button", onClick: this.createAction }, i18n.actionCreate)),
            React.createElement("div", { className: S.faqMainBox }, loading ?
                React.createElement(SpinnerBlock, null)
                :
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: S.faqHeader },
                            React.createElement("div", { className: S.title }, i18n.faqFormTitle),
                            React.createElement("div", { className: S.content }, i18n.faqFormContent),
                            React.createElement("div", { className: S.action }, i18n.adminsAction)),
                        React.createElement("div", { className: S.faqList }, faqs.map(function (faq, i) { return (React.createElement(FaqItem, { key: i, faq: faq, isUpdated: _this.onUpdate, updateItem: _this.updateItem, changePosition: _this.changePosition })); })))),
            this.state.showCreateModal &&
                React.createElement(AddFaqModal, { onClose: this.onCloseAdd, isUpdated: this.isUpdatedAdd, faq: updateItem })));
    };
    FaqView = __decorate([
        inject('store'),
        observer
    ], FaqView);
    return FaqView;
}(React.Component));
export { FaqView };
