import { del, EndPoints, get, post } from '../';
export function list(showAll) {
    if (showAll === void 0) { showAll = false; }
    var data = showAll ? { all: 1 } : null;
    return get(EndPoints.customers, data);
}
export function createCustomer(request) {
    return post(EndPoints.customersCreate, request);
}
export function updateCustomer(request) {
    return post(EndPoints.customersUpdate, request);
}
export function deleteCustomer(request) {
    return del(EndPoints.customersDelete, request);
}
