import { __assign } from "tslib";
import { del, EndPoints, get } from '../index';
export function list(query) {
    if (query === void 0) { query = null; }
    var data = { limit: 100 };
    if (query) {
        data = __assign({ limit: 100 }, query);
    }
    return get(EndPoints.documents, data);
}
export function updateDocument(request) {
    return del(EndPoints.documentsUpdate, request);
}
export function deleteDocument(request) {
    return del(EndPoints.documentsDelete, request);
}
